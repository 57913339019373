/**
 *
 */
export default class ApiService {

    constructor(config) {
        this.config = config
    }

    async fetch(url, callback) {

        const PREFIXED_URL = this.config.url_prefix + url;

        fetch(PREFIXED_URL)
            .then(response => response.json())
            .then(data => {
                if (data) {
                    callback(data)
                }
            }, err => {
                console.error("err", err);
            });
    }
}
