import React, {useEffect, useState} from 'react';

import {AppBar, Box, Card, CardContent, LinearProgress, Tab, Table, Tabs, Typography} from '@material-ui/core';


import {CalendarToday, Menu} from '@material-ui/icons';
import {dishFilter} from "../Service/DishFilter";
import {CustomerMap} from "./CustomerMap";
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import {DishRow} from "./Dish";
import {TabPanel} from "./TabPanel";


export const MenuItem = ({menu, customer, APISERVICE, filters, type}) => {


    const url = `/public-api/menu-dishes/${customer.ownerSlug}/${menu.id}`;

    const [dishes, setDishes] = useState([]);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        APISERVICE.fetch(url, (dishes) => {
            setDishes(dishes);
            setLoading(false)
        })

    }, []);


    const filteredDishes = dishFilter(dishes, filters);


    return <>
        <Collapse in={loading}>
            {loading && <LinearProgress/>}
        </Collapse>
        <Collapse
            in={!loading}
            timeout={"auto"}
        >
            <Card elevation={1}>
                <CardContent>
                    <br/>
                    <h3 aria-label={menu.name.toUpperCase()}><b><i>{menu.name.toUpperCase()}</i></b></h3>
                    <br/>
                    {filteredDishes['menuSection'] &&
                    Object.keys(filteredDishes['menuSection']).map(
                        ms => {

                            const currentDishes = Array.from(filteredDishes['menuSection'][ms]).filter(i => i);

                            return <Box sx={{'mb': 2}}>
                                <Card elevation={1}>
                                    <CardContent>
                                        <h5><b><i>{ms.toUpperCase()}</i></b></h5><br/>
                                        {(currentDishes && currentDishes.length) ? <Table size={"small"}>
                                            {currentDishes ? currentDishes.map(
                                                d => <DishRow
                                                    dish={d}
                                                    type={type}
                                                    customer={customer}
                                                    APISERVICE={APISERVICE}/>
                                            ) : 'Aún No hay platos en esté menú'}
                                        </Table> : null
                                        }
                                    </CardContent>
                                </Card>
                            </Box>
                        }
                    )
                    }

                    <Table size={"small"}>
                        {
                            Object.keys(filteredDishes).map(
                                ms => {

                                    if (ms === 'menuSection') {
                                        return null
                                    }

                                    const currentDish = filteredDishes[ms];

                                    return <>
                                        {currentDish ? <DishRow
                                            dish={currentDish}
                                            customer={customer}
                                            type={type}
                                            APISERVICE={APISERVICE}/> : null}
                                    </>
                                }
                            )
                        }
                    </Table>


                    <br/>
                    <CardContent>
                        <p>
                            <i><b>{menu.description}</b></i>
                        </p>
                        <p>
                            <i>
                                <b>
                                    {menu?.pvp ? (menu?.pvp / 100).toFixed(2) : ''} {menu?.pvp ? "€" : ''}
                                </b>
                            </i>
                        </p>
                    </CardContent>
                </CardContent>
            </Card>
        </Collapse>
    </>
};

const MenuView =
    (
        {
            daily,
            standard,
            event,
            customer,
            APISERVICE,
            filters
        }
    ) => {

        const [value, setValue] = useState(0);

        const handleChange = (e, value) => {
            setValue(value);
        };

        const menuItems = standard.menus.filter(m => m.webPublic);
        const dailyItems = daily.menus.filter(m => m.webPublic);
        const eventItems = event.menus.filter(m => m.webPublic);

        let count = 0;


        return <div className={"full relative"}>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    aria-label="Menús">
                    {dailyItems.length ? <Tab label={<>
                        <Menu/>
                        Menús
                    </>} color={"secondary"}/> : null}
                    {eventItems.length ? <Tab label={<>
                        <CalendarToday/> Eventos
                    </>} color={"secondary"}/> : null}
                    {menuItems.map(m => <Tab label={m.name}/>)}
                </Tabs>
            </AppBar>
            <div className="container py-4" id={"menu-tabs"}>

                {dailyItems.length ? <TabPanel value={value} index={count}>


                    {dailyItems.map(
                        (m, loop) => <>
                            <Paper>
                                <MenuItem menu={m} APISERVICE={APISERVICE} customer={customer} filters={filters}
                                          type={"DAILY"}/>
                            </Paper>
                            <br/><br/>
                        </>)
                    }
                </TabPanel> : null}

                {(dailyItems.length && count++) ? '' : ''}

                {eventItems.length ? <TabPanel value={value} index={count}>
                    {eventItems.map(
                        (m, loop) => <Paper>
                            <MenuItem menu={m} APISERVICE={APISERVICE} customer={customer} filters={filters}
                                      type={"EVENT"}/>
                            <br/><br/>
                        </Paper>)
                    }
                </TabPanel> : null}

                {(eventItems.length && count++) ? '' : ''}

                {menuItems.map(
                    (m, loop) => <TabPanel value={value} index={loop + count} id={`menu-${m.id}`}>
                        <MenuItem menu={m} APISERVICE={APISERVICE} customer={customer} filters={filters}
                                  type={"STANDARD"}/>
                    </TabPanel>)
                }
            </div>
            <AppBar position="static">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="scrollable"
                    allowScrollButtonsMobile
                    aria-label="Menús">
                    {dailyItems.length ? <Tab href="#menu-link" label={<>
                        <Menu/>
                        Menús
                    </>} color={"secondary"}/> : null}
                    {eventItems.length ? <Tab href="#menu-link" label={<>
                        <CalendarToday/> Eventos
                    </>} color={"secondary"}/> : null}
                    {menuItems.map(m => <Tab href="#menu-link" label={m.name}/>)}
                </Tabs>
            </AppBar>
            {(customer.ownerLatitude != null && customer.ownerLongitude != null) &&
            <CustomerMap customer={customer} APISERVICE={APISERVICE}/>}
        </div>
    };

export const CustomerMenus = ({customer, APISERVICE, filters}) => {


    const url = `/public-api/menus/${customer.ownerSlug}/STANDARD`;
    const dailyUrl = `/public-api/menus/${customer.ownerSlug}/DAILY`;
    const eventUrl = `/public-api/menus/${customer.ownerSlug}/EVENT`;

    const [standard, setStandard] = useState(null);
    const [daily, setDaily] = useState(null);
    const [event, setEvent] = useState(null);

    useEffect(() => {

        APISERVICE.fetch(url,
            (data) => {
                setStandard(data);
            }
        )

    }, []);

    useEffect(() => {

        APISERVICE.fetch(dailyUrl,
            (data) => {
                setDaily(data);
            }
        )

    }, []);

    useEffect(() => {

        APISERVICE.fetch(eventUrl,
            (data) => {
                setEvent(data);
            }
        )

    }, []);

    return (daily && standard && event) ? <MenuView
        standard={standard}
        daily={daily}
        event={event}
        APISERVICE={APISERVICE}
        customer={customer}
        filters={filters}
    /> : <LinearProgress/>

};
