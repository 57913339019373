import {
    Button,
    CardContent,
    Dialog,
    Divider,
    Grid,
    LinearProgress,
    MenuItem as MenuItemS,
    Select,
    Tab, TableCell, TableRow
} from "@material-ui/core";
import uniq from "lodash/uniq";
import {AllergensBlock} from "./Allergens";
import React, {useEffect, useState} from "react";
import {NutritionalSetBlock} from "./NutritionalValuesBlocks";
import {Legend, Pie, PieChart, Tooltip} from "recharts";
import {DishImage} from "./DishImages";
import Chip from "@material-ui/core/Chip";
import {FOOD_CHARACTERISTICS_NAMES} from "../Model/Dish";
import {CheckBox, Close, InfoOutlined} from "@material-ui/icons";
import Collapse from "@material-ui/core/Collapse/Collapse";
import {MuiTabs} from "./MuiTabs";
import {IngredientBlock} from "./Ingredient";


export const DishInfo = ({data, dish, customer, APISERVICE}) => {

    return <CardContent>
        <IngredientBlock ingredients={dish.fullIngredients}/>
    </CardContent>
};

export const DishAlergens = ({data, dish, customer, APISERVICE}) => {

    const allergens = uniq([].concat(dish.allergens).concat(dish.ingredientChildAllergens).filter(i => i));

    return <CardContent>
        <AllergensBlock allergens={allergens}/>
    </CardContent>
};

export const DishNutritionalValues = ({data, dish, customer, APISERVICE}) => {


    const rationWeight = (dish.recipeWeight ?? 1) / (dish.rationCount ?? 1);

    const pCent = rationWeight / 100;

    const [mult, setMult] = useState(1);

    const nData = data ? [
        {'name': 'Grasas', value: Math.ceil(data.fat * mult), fill: '#ff5555'},
        {'name': 'Proteínas', value: Math.ceil(data.proteins * mult ), fill: '#5555FF'},
        {'name': 'Hidratos de carbono', value: Math.ceil(data.carbohydrates * mult), fill: '#2AA92A'}
    ] : null;


    const renderLabel = (r) => {
        return `${r.value}g`
    };



    return <CardContent>

        <CardContent>
            <Select value={mult} onChange={(e) => setMult(e.target.value)}>
                <MenuItemS value={1}>
                    Por 100g
                </MenuItemS>
                <MenuItemS value={pCent}>
                    Por ración {rationWeight}g
                </MenuItemS>
            </Select>
        </CardContent>

        {customer.showFullNutritionalValues && <NutritionalSetBlock record={dish} mult={mult}/>}
        <div className="tcenter">
            <PieChart width={290} height={290}>
                <Pie
                    dataKey="value"
                    isAnimationActive={false}
                    data={nData}
                    cx="50%"
                    cy="50%"
                    outerRadius={80}
                    fill="#8884d8"
                    label={renderLabel}
                />
                <Tooltip/>
                <Legend/>
            </PieChart>
        </div>
    </CardContent>
};

export const DishDialog = ({dish, APISERVICE, customer, onClose, fullWidth=false, fullScreen=false}) => {

    const url = `/public-api/dishes/${customer.ownerSlug}/${dish.id}`;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        APISERVICE.fetch(url, (dish) => {
            setData(dish);
            setLoading(false);
        })

    }, []);


    const pvp = dish?.pvp?.amount ? `${(dish.pvp.amount /100).toFixed(2)}€ ` : '';

    let tabs = [];
    let panels = [];


    if (data) {


        if (data.dishImages.length) {
            tabs.push(<Tab label={"Presentación"}/>);
        }

        tabs.push(<Tab label={"Alérgenos"}/>);
        tabs.push(<Tab label={"Valores nutricionales"}/>);
        tabs.push(<Tab label={"Elaboración"}/>);

        if (data.dishImages.length) {
            panels.push(<DishImage data={data} customer={customer} dish={data} APISERVICE={APISERVICE}/>);
        }

        panels.push(<DishAlergens data={data} customer={customer} dish={dish} APISERVICE={APISERVICE}/>);
        panels.push(<DishNutritionalValues data={data} customer={customer} dish={data} APISERVICE={APISERVICE}/>);
        panels.push(<DishInfo data={data} customer={customer} dish={data} APISERVICE={APISERVICE}/>);


    }


    return <Dialog
        open={true}
        maxWidth={"xl"}
        scroll={"body"}
        fullWidth={true}
        fullScreen={true}
    >
        <CardContent>
            <Grid container>
                <Grid item xs={7}>
                    <h4>{dish.name}</h4>
                    <h6>{dish.foodCharacteristics.map(
                        m => <>
                            <Chip
                                label={FOOD_CHARACTERISTICS_NAMES[m]}
                                icon={<CheckBox />}
                                color={"success"}
                            />
                        </>
                    )}</h6>
                </Grid>
                <Grid item xs={5}>
                    <Button
                        variant={"outlined"}
                        startIcon={<Close />}
                        style={{'float' : 'right' }}
                        onClick={() => onClose()}>Cerrar
                    </Button>
                </Grid>
            </Grid>
            <Divider />
        </CardContent>
        <Collapse in={loading} timeout={800}>
            {loading && <LinearProgress/>}
        </Collapse>
        <Collapse in={!loading} timeout={800}>
            <div className="dialog-content">
                {data ? <MuiTabs
                    var
                    tabs={tabs}
                    panels={panels}
                /> : <LinearProgress/>
                }
            </div>
            <div>
                <CardContent>
                    <h5 className={"text-right"}>
                        <b>{pvp}</b>
                    </h5>
                    <p className={"text-right"}>
                        {dish.pvpDescription ?? ''}
                    </p>

                </CardContent>
            </div>
        </Collapse>
    </Dialog>;
};

export const DishRow = ({dish, customer, APISERVICE, type}) => {


    const [showDialog, setShowDialog] = useState(false);

    const allergens = uniq([].concat(dish.allergens).concat(dish.ingredientChildAllergens).filter(i => i));

    const pvp = dish?.pvp?.amount;

    const name = dish?.commercialName ?? dish?.name;

    return (dish && !dish.outOfService) ? <TableRow>

        <TableCell onClick={() => setShowDialog(true)}>
            <h6 aria-label={name}><InfoOutlined className="info-icon"/> <b>{name}</b></h6>
            {dish?.menusection?.name}
            <AllergensBlock allergens={allergens}/>
        </TableCell>
        {type === 'STANDARD' ?
            <TableCell className={"text-right"}>
            <span className={"bold dish-price"}>
                {pvp ? (pvp / 100).toFixed(2) : ''} {pvp ? "€" : ''}
            </span>
            </TableCell> : null}
        {showDialog &&
        <DishDialog dish={dish} customer={customer} APISERVICE={APISERVICE} onClose={() => setShowDialog(false)}/>}
    </TableRow> : null
};
