//TODO: implement translator

import React from "react";
import {MuiTabs} from "./MuiTabs";


import {CardContent, Tab, Table, TableCell, TableHead, TableRow} from "@material-ui/core";

export const formatNutritionalValue = (pCent = 1, value) => {

    if (value < 0) return 'Tr';


    if (value !== null && value >= 0) {
        return (pCent * value).toFixed(2)
    }

    return '-';
};


const __t = {
    t: (e) => e
};

export const NutritionalSetBlock = ({record, mult = 1}) => {


    return <>
        <MuiTabs
            tabs={[
                <Tab label={"Energía y macronutrientes"}/>,
                <Tab label={"Minerales"}/>,
                <Tab label={"Vitaminas"}/>,
                <Tab label={"Lípidos"}/>
            ]
            }
            panels={[
                <CardContent>
                    <Table size="small" dense>
                        <TableHead>
                            <TableCell>{__t.t("Energía KCAL")}</TableCell>
                            <TableCell>{__t.t("Proteínas")}<br/>(g)</TableCell>
                            <TableCell>{__t.t("Grasas")}<br/>(g)</TableCell>
                            <TableCell>{__t.t("De la cuales, saturadas")}<br/>(g)</TableCell>
                            <TableCell>{__t.t("Carbohidratos")}<br/>(g)</TableCell>
                            <TableCell>{__t.t("Azúcares")}<br/>(g)</TableCell>
                            <TableCell>{__t.t("Sal")}<br/>(g)</TableCell>
                            <TableCell>{__t.t("Fibra")}<br/>(g)</TableCell>
                        </TableHead>
                        <TableRow>
                            <TableCell>{formatNutritionalValue(mult, record.energyKCAL)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.proteins)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.fat)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.saturatedFat)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.carbohydrates)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.sugar)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.salt)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.fiber)}</TableCell>
                        </TableRow>
                    </Table>
                </CardContent>,
                <CardContent>
                    <Table size="small">
                        <TableHead>
                            <TableCell>{__t.t("Calcio")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Hierro")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Yodo")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Magnesio")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Zinc")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Sodio")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Potasio")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Fosforo")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Selenio")}<br/>μg</TableCell>
                        </TableHead>
                        <TableRow>
                            <TableCell>{formatNutritionalValue(mult, record.calcium)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.iron)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.iodine)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.magnesium)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.zinc)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.sodium)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.potasium)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.phosphorus)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.selenium)}</TableCell>
                        </TableRow>
                    </Table>
                </CardContent>,
                <CardContent>
                    <Table size="small">
                        <TableHead>
                            <TableCell>{__t.t("Tiamina")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Rivoflavina")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Eq. Niacina")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Vitamina b6")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Folato")}<br/>μg</TableCell>
                            <TableCell>{__t.t("Vitamina B12")}<br/>μg</TableCell>
                            <TableCell>{__t.t("Vitamina C")}<br/>mg</TableCell>
                            <TableCell>{__t.t("Vitamina A Eq retinol")}<br/>μg</TableCell>
                            <TableCell>{__t.t("Retinol")}<br/>μg</TableCell>
                            <TableCell>{__t.t("Carotenos provitamina A")}<br/>μg</TableCell>
                            <TableCell>{__t.t("Vitamina D")}<br/>μg</TableCell>
                            <TableCell>{__t.t("Vitamina E")}<br/>mg</TableCell>
                        </TableHead>
                        <TableRow>
                            <TableCell>{formatNutritionalValue(mult, record.thiamine)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.riboflavin)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.niacin)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.vitaminB6)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.folate)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.vitaminB12)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.vitaminC)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.vitaminA)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.retinol)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.carotenes)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.vitaminD)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.vitaminE)}</TableCell>
                        </TableRow>
                    </Table>
                </CardContent>,
                <CardContent>
                    <Table size="small">
                        <TableHead>
                            <TableCell>{__t.t("Grasas saturadas")}<br/>(g)</TableCell>
                            <TableCell>{__t.t("Grasas monoinsaturadas")}<br/>(g)</TableCell>
                            <TableCell>{__t.t("Grasas poliinsaturadas")}<br/>(g)</TableCell>
                            <TableCell>{__t.t("Colesterol")}<br/>(mg)</TableCell>
                        </TableHead>
                        <TableRow>
                            <TableCell>{formatNutritionalValue(mult, record.saturatedFat)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.fatMono)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.fatPoly)}</TableCell>
                            <TableCell>{formatNutritionalValue(mult, record.cholesterol)}</TableCell>
                        </TableRow>
                    </Table>
                </CardContent>
            ]}
        />
    </>;
};
