import React, {useEffect, useState} from "react";
import { CardContent } from "@material-ui/core";
import {HtmlBlock} from "./HtmlBlock";

const ImageBlock = ({images, APISERVICE, dish, logoUrl}) => {

    const data = [].concat(images.map(i => i));
    const image = data ? data[0].match(/[0-9]+$/)[0] : null;
    const imgUrl = image
        ? `${APISERVICE.config.url_prefix}/uploads/media/dish/0001/01/thumb_cropped_${image}.png`
        : logoUrl;

    return <div>
        <img className={"img img-fluid"} src={imgUrl} alt=""/>
    </div>
};

const BackgroundBlock = ({images, APISERVICE, dish, logoUrl}) => {

    const data = [].concat(images.map(i => i));
    const image = data ? data[0].match(/[0-9]+$/)[0] : null;
    const imgUrl = image
        ? `${APISERVICE.config.url_prefix}/uploads/media/dish/0001/01/thumb_cropped_${image}.png`
        : logoUrl;



    const style = imgUrl ? {
        background: `url("${imgUrl}")`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    } : {};

    return <div
        className={"menu-featured-image-bg relative"}
        style={style}>
        {dish.description &&
        <span>
                <HtmlBlock html={dish.description} />
            </span>
        }
    </div>
};



export const DishImage = ({dish, customer, APISERVICE, logoUrl}) => {

    const url = `/public-api/dishes/${customer?.ownerSlug}/${dish?.id}`;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const dishImages = data?.dishImages;

    useEffect(() => {

        APISERVICE.fetch(url, (dish) => {
            setData(dish);
            setLoading(false);
        })

    }, []);


    if (!data) return null;


    return <CardContent>
        {(dishImages && dishImages.length) ?
            <ImageBlock images={dishImages} APISERVICE={APISERVICE} logoUrl={logoUrl} dish={dish}/>
        : <>
                <p>{dish.description}</p>
            </>
        }
    </CardContent>
};

export const DishBackground = ({dish, customer, APISERVICE, logoUrl}) => {

    const url = `/public-api/dishes/${customer?.ownerSlug}/${dish?.id}`;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);

    const dishImages = data?.dishImages;

    useEffect(() => {

        APISERVICE.fetch(url, (dish) => {
            setData(dish);
            setLoading(false);
        })

    }, []);


    if (!data) return null;


    return <>
        {(dishImages && dishImages.length) ?
            <BackgroundBlock images={dishImages} APISERVICE={APISERVICE} logoUrl={logoUrl} dish={dish}/> : null}
    </>
};
