import {CardContent, List} from "@material-ui/core";
import React from "react";

export const IngredientBlock = ({ingredients}) => {

    const data = ingredients.filter(i => i.public);

    return <CardContent>
        <List>
            {
                data.map(i => {

                        const commercialName = i?.ingredient?.commercialName;
                        const name = i?.ingredient?.name;

                        return <li>
                            {commercialName ?? name}
                        </li>
                    }
                )
            }
        </List>
    </CardContent>
};
