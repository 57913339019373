import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom/index";
import {CardContent, Divider, Grid, LinearProgress, Tab} from "@material-ui/core";
import {DishAlergens, DishDialog, DishInfo, DishNutritionalValues} from "../Blocks/Dish";
import Chip from "@material-ui/core/Chip";
import {FOOD_CHARACTERISTICS_NAMES} from "../Model/Dish";
import {CheckBox} from "@material-ui/icons";
import {DishImage} from "../Blocks/DishImages";
import Collapse from "@material-ui/core/Collapse/Collapse";
import {MuiTabs} from "../Blocks/MuiTabs";
import {Alert} from "@material-ui/lab";

const DishBlock = ({dish, customer, APISERVICE}) => {
    const url = `/public-api/dishes/${customer.ownerSlug}/${dish.id}`;

    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(true);


    useEffect(() => {

        APISERVICE.fetch(url, (dish) => {
            setData(dish);
            setLoading(false);
        })

    }, []);


    const pvp = dish?.pvp?.amount ? `${(dish.pvp.amount / 100).toFixed(2)}€ ` : '';

    let tabs = [];
    let panels = [];


    if (data) {


        if (data.dishImages.length) {
            tabs.push(<Tab label={"Presentación"}/>);
        }

        tabs.push(<Tab label={"Alérgenos"}/>);
        tabs.push(<Tab label={"Valores nutricionales"}/>);

        if (customer.showDishIngredients) {
            tabs.push(<Tab label={"Elaboración"}/>);
        }

        if (data.dishImages.length) {
            panels.push(<DishImage data={data} customer={customer} dish={data} APISERVICE={APISERVICE}/>);
        }

        panels.push(<DishAlergens data={data} customer={customer} dish={dish} APISERVICE={APISERVICE}/>);
        panels.push(<DishNutritionalValues data={data} customer={customer} dish={data} APISERVICE={APISERVICE}/>);

        if (customer.showDishIngredients) {
            panels.push(<DishInfo data={data} customer={customer} dish={data} APISERVICE={APISERVICE}/>);
        }


    }


    if (!dish.publishable) {

        return <Alert severity={"error"}>
            Esta elaboración no es de acceso público.
        </Alert>

    }


    return <>
        <CardContent>
            <Grid container>
                <Grid item xs={12}>
                    <h4>{dish.name}</h4>
                    <h6>{dish.foodCharacteristics.map(
                        m => <>
                            <Chip
                                label={FOOD_CHARACTERISTICS_NAMES[m]}
                                icon={<CheckBox/>}
                                color={"success"}
                            />
                        </>
                    )}</h6>
                </Grid>
            </Grid>
            <Divider/>
        </CardContent>
        <Collapse in={loading} timeout={800}>
            {loading && <LinearProgress/>}
        </Collapse>
        <Collapse in={!loading} timeout={800}>
            <div className="dialog-content">
                {data ? <MuiTabs
                    var
                    tabs={tabs}
                    panels={panels}
                /> : <LinearProgress/>
                }
            </div>
            <div>
                <CardContent>
                    <h5 className={"text-right"}>
                        <b>{pvp}</b>
                    </h5>
                    <p className={"text-right"}>
                        {dish.pvpDescription ?? ''}
                    </p>

                </CardContent>
            </div>
        </Collapse>
    </>;
};

export default ({APISERVICE}) => {


    let params = useParams();

    let slug = params.ownerSlug ?? "demo";
    let id = params.dishId;

    const [data, setData] = useState(null);
    const [customer, setCustomer] = useState(null);
    const url = `/public-api/dishes/${slug}/${id}`;

    const customerUrl = `/public-api/customer-config/${slug}`;


    useEffect(() => {

        APISERVICE.fetch(url, d => {
            setData(d)
        })

    }, []);

    useEffect(() => {

        APISERVICE.fetch(customerUrl, d => {
            setCustomer(d)
        })

    }, []);

    const close = () => {
        window.history.back();
    };

    return (data && customer) ? (
        <>
            <DishBlock
                dish={data}
                customer={customer}
                APISERVICE={APISERVICE}
                onClose={() => close()}
            />
        </>
    ) : <LinearProgress/>

};
