//TODO: Get values from API

export const FOOD_CHARACTERISTICS = [
    { 'id' : 1,   name: "Bajo en azúcares"},
    { 'id' : 2,   name: "Alto en proteínas"},
    { 'id' : 3,   name: "Vegano"},
    { 'id' : 4,   name: "Bajo en calorías "},
    { 'id' : 5,   name: "Bajo en sal"},
    { 'id' : 6,   name: "Kosher"},
    { 'id' : 7,   name: "Halal"},
    { 'id' : 8,   name: "Vegetal"}
];

export const FOOD_CHARACTERISTICS_NAMES = {
    "/api/food_characteristics/1" : "Bajo en azúcares",
    "/api/food_characteristics/2" : "Alto en proteínas",
    "/api/food_characteristics/3" : "Vegano",
    "/api/food_characteristics/4" : "Bajo en calorías ",
    "/api/food_characteristics/5" : "Bajo en sal",
    "/api/food_characteristics/6" : "Kosher",
    "/api/food_characteristics/7" : "Halal",
    "/api/food_characteristics/8" : "Vegetal"
};

export const DEFAULT_FILTERS = {
    'allergens': {},
    'characteristics' : {},
    'price': [0, 100],
    'proteins': 100,
    'fat': 100,
    'carbohydrates': 100,
};
