/**
 *
 * @param dishAllergens
 * @param allergens
 */
const matchAllergens = (dishAllergens, allergens) => {

    let pass = true;


    if (typeof dishAllergens === 'object') {

        Object.values(dishAllergens).forEach(da => {
            Object.keys(allergens).forEach(a => {

                if ((allergens[a] === true) && parseInt(a) === parseInt(da.id)) {
                    pass = false;
                }
            })
        });

    }

    return pass;
};


const matchCharacteristics = (dishCharacteristics, characteristics) => {

    if (!characteristics || Object.keys(characteristics).length === 0) return true;

    let pass = true;

    let filter = [];

    const dishChars = dishCharacteristics.map(m => parseInt(m.match(/[0-9]+$/)[0]));

    Object.keys(characteristics).forEach(f => {
        if (characteristics[f]) {
            filter.push(parseInt(f));
        }
    });

    filter.forEach(f => {
        if (!dishChars.includes(f)) {
            pass = false;
        }
    });

    return pass;
};

/**
 *
 * @param dish
 * @param price
 * @returns {boolean}
 */
const matchPrice = (dish, price) => {

    let dishPrice = dish.pvpAmount ? dish.pvpAmount : null ;

    if (dish?.pvp?.amount) {
        dishPrice = dish?.pvp?.amount / 100;
    }

    if (!dishPrice) return true;

    const max = price[1];
    const min = price[0];


    return Boolean(dishPrice <= max && dishPrice >= min);

};


const matchName = (dish, name) => {

   if (!name) return true;

   const dishName = String(dish?.name);

   const regexp = new RegExp(`.*${name}.*`, 'gi');


   return dishName.match(regexp) !== null;

};


const matchMax = (dish, field, percent) => {

    const dishField = dish[field];

    if (!dishField) return true;

    return Boolean(dishField <= percent);

};



const dishPassFilters = (dish, filters) => {



    let { allergens, price, fat, proteins, carbohydrates, name, characteristics } = filters;

    const dishAllergens = dish?.allergens;
    const dishCharacteristics = dish?.foodCharacteristics;

    const passName = matchName(dish, name);

    const passAllergens = matchAllergens(dishAllergens, allergens);
    const passCharacteristics = matchCharacteristics(dishCharacteristics, characteristics);

    const passPrice = matchPrice(dish, price);

    const passFat = matchMax(dish, "fat", fat);
    const passProteins = matchMax(dish, "proteins", proteins);
    const passCarbohydrates = matchMax(dish, "carbohydrates", carbohydrates);


    return (passAllergens
        && passPrice
        && passFat
        && passProteins
        && passCarbohydrates
        && passName
        && passCharacteristics
    );

};
/**
 *
 * @param dishes
 * @param filters
 * @returns {*}
 */
export const dishFilter = (dishes, filters) => {


    let filteredDishes = {'menuSection' : []};

    Object.keys(dishes).forEach(k => {

        if (k !== "menuSection") {

            let dish = dishes[k];

            if (dish) {

                if (dishPassFilters(dish, filters)) {
                    filteredDishes[k] = dish
                }
            }
        }

        else {
            Object.keys(dishes['menuSection']).forEach(ms => {
                dishes['menuSection'][ms].forEach((dish, loop) => {
                    if (dish) {
                        if (dishPassFilters(dish, filters)) {

                            if (!filteredDishes['menuSection'][ms]) {
                                filteredDishes['menuSection'][ms] = [];
                            }

                            filteredDishes['menuSection'][ms][loop] = dish;
                        }
                    }
                })
            })
        }

    });

    return filteredDishes;
};
